<template>
  <div class="MERCH goodsCheck">
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">商品名称:</div> -->
            <el-input
              placeholder="请输入商品名称/通用名"
              v-model="ListApi.goods_name"
              clearable
            ></el-input>
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">供应商名称:</div> -->
            <el-input
              placeholder="请输入供应商名称"
              v-model="ListApi.business_user_name"
              clearable
            ></el-input>
          </div>
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">审核类型:</div> -->
            <el-select
              v-model="ListApi.type"
              clearable
              placeholder="请选择审核类型"
            >
              <el-option
                v-for="item in userType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">审核状态:</div> -->
            <el-select
              v-model="ListApi.status"
              clearable
              placeholder="请选择审核状态"
            >
              <el-option
                v-for="item in auditType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <div class="sch-1 sch-2" style="width: 400px">
            <el-date-picker
              v-model="datelist"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择审核开始日期"
              end-placeholder="请选择审核结束日期"
              unlink-panels
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            />
          </div>
          <!-- 审核人 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">供应商名称:</div> -->
            <el-input
              placeholder="请输入审核人"
              v-model="ListApi.auditor"
              clearable
            ></el-input>
          </div>

          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button type="primary" @click="toSearch1" style="width: 80px">
              查询
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table
            :data="GoodData.data"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
          >
            <el-table-column prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="address" label="提交时间" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.create_time"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.create_time }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="审核类型" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.type_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.type_string }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品通用名" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.content.basicInfo.commonName"
                  placement="bottom"
                >
                  <div v-if="row.content">
                    <div class="exceed">
                      {{ row.content.basicInfo.commonName || "/" }}
                    </div>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品名称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.goods_name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.goods_name || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="规格" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.content.basicInfo.specification"
                  placement="bottom"
                >
                  <div class="exceed">
                    {{ row.content.basicInfo.specification || "/" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="生产厂家" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.manufacturer"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.manufacturer || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="批准文号" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.approval_number"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.approval_number || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="供应商" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.business_user_name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.business_user_name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="auditor" label="审核人" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.auditor"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.auditor }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="审核状态" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.status_string"
                  placement="bottom"
                >
                  <div
                    class="exceed exceed_color1"
                    v-if="row.status_string == '审核失败'"
                  >
                    {{ row.status_string }}
                  </div>
                  <div
                    class="exceed exceed_color"
                    v-if="row.status_string == '待审核'"
                  >
                    {{ row.status_string }}
                  </div>
                  <div
                    class="exceed exceed_color2"
                    v-if="row.status_string == '审核通过'"
                  >
                    {{ row.status_string }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="170"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <div
                    @click="toMessage(1, row, 'true')"
                    v-if="row.status != 1 && allJurisdiction.goodsCheck161"
                  >
                    详情
                  </div>
                  <div
                    @click="toMessage(1, row)"
                    v-if="row.status == 1 && allJurisdiction.goodsCheck162"
                  >
                    立即审核
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          :current-page="GoodData.current_page"
          background
          @current-change="handleCurrentChange"
          :page-size="GoodData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="GoodData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// const GoodMessage = require("@/assets/json/goodsAntd.json");
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      datelist: [],
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "child",
        checkStrictly: true,
      }, //级联选择器配置项
      AreaArr: [],
      ListApi: {
        page: 1,
        start_time: "",
        end_time: "",
        auditor:'',
      },
      GoodData: {}, //商品列表信息
      // 客户类型
      userType: [
        {
          type: 1,
          name: "新建商品",
        },
        {
          type: 2,
          name: "申请建档",
        },
        {
          type: 6,
          name: "共享商品",
        },
        {
          type: 3,
          name: "修改图片",
        },
        {
          type: 4,
          name: "修改商品名称",
        },
        {
          type: 5,
          name: "修改分佣比例",
        },
        // {
        //   type: 99,
        //   name: "资料库更新",
        // },
      ],
      // 审核状态
      auditType: [
        {
          type: 1,
          name: "待审核",
        },
        {
          type: 2,
          name: "审核通过",
        },
        {
          type: 3,
          name: "审核失败",
        },
      ],
    };
  },
  computed: {
    ...commonIndex.mapState([
      "mainHright",
      "allJurisdiction",
      "allJurisdiction",
      "detailsSeach",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.ListApi = this.detailsSeach[router];
      this.datelist = this.detailsSeach[router].datelist;
      // this.cascaderValue=this.detailsSeach[router].cascaderValue
    }
    this.toSearch();
  },
  methods: {
    ...mapActions(["postmemberAuditsavelist", "getplatformGoodsis_audit"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    //
    handleCurrentChange(e) {
      this.ListApi.page = e;
      this.toSearch();
    },
    toSearch1() {
      this.ListApi.page = 1;
      this.toSearch();
    },
    async toSearch() {
      if (this.datelist && this.datelist.length > 0) {
        this.ListApi.start_time = this.datelist[0];
        this.ListApi.end_time = this.datelist[1];
      } else {
        this.ListApi.start_time = "";
        this.ListApi.end_time = "";
      }
      let data = await this.postmemberAuditsavelist(this.ListApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.ListApi };
      save.router = router;
      this.changeSeach(save);
      data.data.data.forEach((item) => {
        if (item.content) {
          item.content = JSON.parse(item.content);
        } else {
          item.content = { basicInfo: {} };
        }
      });
      this.GoodData = data.data;
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    async toMessage(val, row, steur) {
      //判断是否为详情
      let steured = false;
      if (steur) {
        steured = true;
      }
      switch (row.status) {
        // 待审核
        case 1:
          if (row.type == 1) {
            this.$router.push({
              path: "/addGood",
              query: {
                id: row.id,
                steured,
                platformGoodsInfoId: row.platform_goods_info_id,
              },
            });
            return;
            let data = await this.getplatformGoodsis_audit({
              platform_goods_info_id: row.platform_goods_info_id,
            });
            if (data.code == 200) {
              this.$router.push({ path: "/addGood", query: { id: row.id } });
              //console.log(data.data);
              if (data.data.is_audit == 0) {
                this.$router.push({
                  path: "/dataBank",
                  query: { id: row.platform_goods_info_id, ListID: row.id },
                });
              } else {
                this.$router.push({
                  path: "/addGood",
                  query: { id: row.id },
                });
              }
            } else {
              this.$message({
                message: data.message,
                type: "warning",
              });
            }
          } else if (row.type == 2) {
            this.$router.push({
              path: "/GoodFiling",
              query: { id: row.id, steured },
            }); //申请建档
          } else if (row.type == 3 || row.type == 4) {
            this.$router.push({
              path: "/redactGood",
              query: {
                id: row.id,
                type: row.type,
                platform_goods_info_id: row.goods_info_id,
                steured,
              },
            }); //编辑
          } else if (row.type == 5) {
            this.$router.push({
              path: "/cedingGoods",
              query: {
                id: row.id,
                platform_goods_info_id: row.goods_info_id,
                steured,
              },
            });
          } else if (row.type == 6) {
            this.$router.push({
              path: "/goodshared",
              query: {
                id: row.id,
                platform_goods_info_id: row.goods_info_id,
                steured,
              },
            });
          }
          break;
        // 通过审核
        case 2:
          if (row.type == 3 || row.type == 4) {
            this.$router.push({
              path: "/redactGood",
              query: { id: row.id, type: row.type, steured },
            }); //编辑
          } else if (row.type == 6) {
            this.$router.push({
              path: "/goodshared",
              query: {
                id: row.id,
                steured,
                platform_goods_info_id: row.goods_info_id,
              },
            });
          } else if (row.type == 5) {
            this.$router.push({
              path: "/cedingGoods",
              query: {
                id: row.id,
                platform_goods_info_id: row.goods_info_id,
                steured,
              },
            });
          } else {
            this.$router.push({
              path: "/addGood",
              query: {
                id: row.id,
                steured,
                platformGoodsInfoId: row.platform_goods_info_id,
              },
            });
          }
          break;
        // 未通过审核
        case 3:
          if (row.type == 3 || row.type == 4) {
            this.$router.push({
              path: "/redactGood",
              query: {
                id: row.id,
                type: row.type,
                steured,
                platform_goods_info_id: row.goods_info_id,
              },
            }); //编辑
          } else if (row.type == 6) {
            this.$router.push({
              path: "/goodshared",
              query: {
                id: row.id,
                steured,
                platform_goods_info_id: row.goods_info_id,
              },
            });
          } else if (row.type == 5) {
            this.$router.push({
              path: "/cedingGoods",
              query: {
                id: row.id,
                platform_goods_info_id: row.goods_info_id,
                steured,
              },
            });
          } else {
            this.$router.push({
              path: "/addGood",
              query: {
                id: row.id,
                steured,
                platformGoodsInfoId: row.platform_goods_info_id,
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 250px;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
    }
  }
}
.exceed_color {
  color: #0e42d2;
}
.exceed_color1 {
  color: #f34444;
}
.exceed_color2 {
  color: #3dbb2b;
}
::v-depp .-el-input__inner {
  height: 40px;
}
::v-deep .el-input__inner {
  border-radius: 4px !important;
}
::v-deep .el-cascader {
  width: 100% !important ;
}
::v-deep .el-select {
  width: 100% !important ;
}
</style>
